<template>
  <div id="videoPlay">
    <div class="title gn-full-x gn-flex-center">{{ name }}</div>
    <div class="gn-full-x gn-flex-center">
      <video
        ref="videoPlay"
        class="gn-full-x gn-bg-black"
        style="height: 206px"
        controls
        autoplay
        :poster="cover"
      >
        <source :src="videoUrl" type="video/mp4" />
        <source :src="videoUrl" type="video/ogg" />
        <source :src="videoUrl" type="video/webm" />
      </video>
    </div>
    <div class="card">
      <span>{{ title }}</span>
    </div>
    <div class="otherList">
      <div class="content">
        <ul>
          <li
            v-for="(item, index) in videos"
            :key="index"
            @click="changeVideo(item)"
          >
            <div class="video">
              <img :src="item.imgs" class="gn-full" />
            </div>
            <div class="info">
              <div class="title gn-line-1">{{ item.name }}</div>
              <!-- <div class="time">{{ item.create_time }}</div> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/admin'
import { getPublishListByType } from '@/api/officialWebsite'

export default {
  name: 'videoPlay',
  metaInfo () {
    return {
      title: this.$route.query.name
    }
  },
  data () {
    return {
      advisory_type: '',
      videoUrl: '',
      name: '',
      videos: [],
      cover: '',
      videosCover: [
        require('@/assets/images/newbazaarIntroduce/instructions1.png'),
        require('@/assets/images/newbazaarIntroduce/instructions2.png'),
        require('@/assets/images/newbazaarIntroduce/instructions3.png'),
        require('@/assets/images/newbazaarIntroduce/instructions4.png'),
        require('@/assets/images/newbazaarIntroduce/instructions5.png'),
        require('@/assets/images/newbazaarIntroduce/instructions6.png')
      ],
      reqParams: {
        page: 1,
        per_page: 100,
        search_data: '', // 关键字搜索
        advisory_type: '3', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      }
    }
  },
  created () {
    const { name, videoUrl, type, cover } = this.$route.query
    console.log(name, '什么东西')
    this.advisory_type = type
    this.videoUrl = videoUrl
    this.name = name
    this.cover = cover

    this.getVideoData(type)
  },
  computed: {
    title () {
      let text = ''

      if (this.advisory_type === '3') {
        text = '相关视频'
      } else if (this.advisory_type === '4') {
        text = '使用说明'
      }

      return text
    }
  },
  methods: {
    async getVideoData (type) {
      if (type === '3') {
        // 视频展示
        this.reqParams.advisory_type = this.advisory_type
        const { list } = await getArticleList(this.reqParams)

        this.videos = (list || []).map((item) => {
          return {
            ...item,
            name: item.title,
            imgs: item.title_image // 封面图
          }
        })
      } else if (type === '4') {
        // 使用说明
        const data = await getPublishListByType({
          publish_type: ['7'] // 7:使用说明
        })
        this.videos = (data[7] || []).map((item, index) => {
          return {
            ...item,
            imgs: this.videosCover[index] // 封面图
          }
        })
      }
    },
    changeVideo (item) {
      if (item.file || item.video) {
        (this.videoUrl = item.file || item.video),
        (this.name = item.name || item.title)
        this.cover = item.title_image || ''

        this.$nextTick(() => {
          this.$refs.videoPlay.load()

          // 此段代码的目的仅仅是为了切换浏览器地址栏的参数信息
          this.$router.push({
            name: 'mobileVideo',
            query: {
              type: this.advisory_type,
              videoUrl: this.videoUrl,
              name: this.name,
              cover: this.cover
            }
          })
        })
        window.scrollTo(0, 0)

        return
      }

      this.$message.error('暂无视频播放源')
    }
  }
}
</script>

<style lang='scss' scoped>
#videoPlay {
  margin-top: 78px;
  .title {
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .card {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      padding: 43px 0px 4px 0px;
      color: #333333;
      border-bottom: 2px solid #00a0ac;
      box-sizing: border-box;
    }
  }
  .otherList {
    padding: 0px 10px;
    padding-bottom: 10px;
    .content {
      width: 100%;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 48.5%;
          height: auto;
          margin-bottom: 10px;
          .video {
            width: 100%;
            height: 90px;
            img {
              width: 100%;
              height: 100%;
            }
            .icon {
              width: 39px;
              height: 39px;
            }
          }
          .info {
            width: 100%;
            background: #f5f5f5;
            height: auto;
            padding-left: 15px;
            padding-top: 10px;
            padding-bottom: 15px;
            box-sizing: border-box;
            margin-bottom: 5px;
            .title {
              font-size: 14px;
              color: #333333;
              padding: 0;
              margin-bottom: 5px;
            }
            // .time {
            //   font-size: 10px;
            //   color: #666666;
            //   padding: 0;
            //   line-height: 14px;
            // }
          }
        }
      }
    }
  }
}
</style>
